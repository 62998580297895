<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <section v-if="false" class="tourism-product__gallery">
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-1">
          <img class="tourism-product__image" src="/assets/img/pool-hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-2">
          <img class="tourism-product__image" src="/assets/img/hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-3">
          <img class="tourism-product__image" src="/assets/img/hall.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-4">
          <img class="tourism-product__image" src="/assets/img/terrace.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-5">
          <img class="tourism-product__image" src="/assets/img/pool-croped.png" alt="">
      </div>
      <a class="link tourism-product__more-photo" href=""></a>
    </section>

    <section v-if="flight" class="tourism-container">
      <div class="row m-0">
        <div class="col col-md-12 p-b-0">
          <button class="primary-btn primary-btn_sm bg-grey9 m-0" @click="redirectToFlightsHome">
            {{ $t('FLIGHTS.BACK_TO_SEARCH') }}
          </button>
        </div>
        <div class="col col-md-12 p-b-0">
          <h4>{{ $t('FLIGHTS.ORDER_DETAILS') }}</h4>
        </div>
      </div>
      <div class="row m-t-0">
        <div class="col-md-9">
          <div class="bg-white p5 bd-radius">
            <div class="d-flex align-items-center flex-wrap m-t-5">
              <h4 class="mx5 m-b-10">{{ $t('FLIGHTS.TRIP_SUMMARY') }}</h4>
              <TripSummary :flight="flight" class="mx20 m-b-10"></TripSummary>
            </div>
            <div class="row m-t-5 m-l-20">
              <div v-if="flight.to_routes && flight.to_routes.length" class="col-md-4">
                <FligntTicket :routes="flight.to_routes"></FligntTicket>
              </div>
              <div v-if="flight.return_routes && flight.return_routes.length" class="col-md-4">
                <FligntTicket :routes="flight.return_routes"></FligntTicket>
              </div>
              <div class="col-md-4">
                <BaggageInfo :baglimit="flight.baglimit" :bagsprice="flight.bagsprice"></BaggageInfo>
                <CovideNotify class="m-t-15"></CovideNotify>
              </div>
            </div>
            <div v-if="flight.return_routes && flight.return_routes.length && firstReturnRoute" class="row m-t-0 m-b-5">
              <div class="col-md-8 fs14 text-center">
                {{ flight.nights_in_dest }} {{ $t( "FLIGHTS.NIGHTS_IN" ) }} {{ flight.to.city_name }} <b>{{ $t('FLIGHTS.THEN_DEPART_FROM') }}</b> <b>{{ firstReturnRoute.from.airport_name || firstReturnRoute.from.city_name }} ({{ firstReturnRoute.from.airport_code }})</b>
              </div>
            </div>
          </div>

          <div v-if="passengers_data && passengers_data.length">
            <template v-for="(passenger, index) in passengers_data">
              <FlightPassengerInfo v-model="passengers_data[index]" :passenger_ind="index" :flight="flight"></FlightPassengerInfo>
            </template>
          </div>

        </div>
        <div class="col-md-3">
          <FlightResult :flight="flight" :passengers="passengers_data"></FlightResult>
          <button class="primary-btn primary-btn_sm order-btn" @click="openOrderModal">
            {{ $t('FLIGHTS.ORDER') }}
          </button>
        </div>
      </div>
    </section>

    <section class="order-modal">

      <modal
        :width="600"
        :adaptive="true"
        class="CEModal modal-scroll-bar"
        :name="order_modal_name"
        :ref="order_modal_name"
        @closed="closeOrderModal"
      >
        <button class="button cus-close-modal_btn" @click="closeOrderModal">
          <img src="/assets/img/close.svg" alt />
        </button>


          <div class="m-t-50">

              <div class="px10">
                <label for="order-phone">{{ $t('FLIGHTS.PHONE') }}</label>
                <input id="order-phone" type="phone" class="input bd-radius" v-model="phone" />
              </div>
              <div class="px10">
                <label for="order-email">{{ $t('FLIGHTS.EMAIL') }}</label>
                <input id="order-email" type="email" class="input bd-radius" v-model="email" />
              </div>

          </div>
          <div class="pt20 m-b-20">
            <div class="d-flex align-items-end">
              <button
                class="primary-btn primary-btn_sm bg-green6"
                @click="createOrder">
                {{ $t( "FLIGHTS.BOOK_FLIGHT" ) }}
              </button>
              <button class="primary-btn primary-btn_sm bg-grey8" @click="closeOrderModal">
                {{ $t( "COMMON.CLOSE" ) }}
              </button>
            </div>
          </div>

      </modal>
    </section>

    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { flightsService } from "@/services/flights.service";

import  FligntTicket from "../../../components/tourism/tradeShow/FligntTicket.vue";
import  BaggageInfo from "../../../components/tourism/tradeShow/BaggageInfo.vue";
import  CovideNotify from "../../../components/tourism/tradeShow/CovideNotify.vue";
import  TripSummary from "../../../components/tourism/tradeShow/TripSummary.vue";
import  FlightResult from "../../../components/tourism/flight/FlightResult.vue";
import  FlightPassengerInfo from "../../../components/tourism/flight/FlightPassengerInfo.vue";

export default {
  name: "FlightDetails",
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary,
    FlightResult,
    FlightPassengerInfo
  },
  data: () => {
    return {
      provider: null,
      booking_token: null,
      passengers: null,
      baggage: null,

      adults: null,
      children: null,
      infants: null,

      adult_hold_bag: null,
      adult_hand_bag: null,
      child_hold_bag: null,
      child_hand_bag: null,

      soft_check_flight_in_process: false,
      checkFlightsInterval: null,

      passengers_data: [],

      flights_check_result: null,
      flights_checked: false,
      price_change: false,
      flights_invalid: false,
      order_modal_name: 'order-modal',
      phone: '',
      email: '',
      session_id: null
    };
  },
  computed: {
    ...mapState({
      load_details_in_process: (state) => state.flights.load_details_in_process,
      create_order_in_process: (state) => state.flights.create_order_in_process,
      flight_details_state: (state) => state.flights.flight_details,
    }),
    ...mapGetters('flights', {
      flight_details: 'flight_details',
    }),
    loading() {
      return this.load_details_in_process || this.create_order_in_process;
    },
    flight() {
      let flight = null;

      flight = this.flight_details(
        this.provider,
        this.booking_token,
        this.adults,
        this.children,
        this.infants,
        this.adult_hold_bag,
        this.adult_hand_bag,
        this.child_hold_bag,
        this.child_hand_bag
      );

      // console.log(flight)
      return flight;
    },
    baggage_availability() {
      return this.flight
      && this.flight.baggage_availability
      && this.flight.baggage_availability
        ? this.flight.baggage_availability : null;
    },
    bagsprice() {
      return this.flight && this.flight.bagsprice ? this.flight.bagsprice : null;
    },
    hand_bagprice() {
      return this.bagsprice && this.bagsprice.hand ? this.bagsprice.hand : 0;
    },
    hold_first_bagprice() {
      return this.bagsprice && this.bagsprice.first_baggage !== null
        ? this.bagsprice.first_baggage : null;
    },
    hold_second_bagprice() {
      return this.bagsprice && this.bagsprice.second_baggage !== null
        ? this.bagsprice.second_baggage : null;
    },
    hold_first_bag_available() {
      return this.hold_first_bagprice !== null;
    },
    hold_second_bag_available() {
      return this.hold_second_bagprice !== null;
    },
    // session_id() {
    //   return this.flight.session_id || null;
    // },
    adult_hold_bag_actual() {
      let adult_hold_bag = 0;
      if (this.passengers_data && this.passengers_data.length) {
        adult_hold_bag = this.passengers_data.filter(p => p.category === 'adults').reduce((accumulator, pass) => {
          return accumulator + +pass.hold_bag;
        }, 0);
      }

      return adult_hold_bag;
    },
    adult_hand_bag_actual() {
      let adult_hand_bag = 0;
      if (this.passengers_data && this.passengers_data.length) {
        adult_hand_bag = this.passengers_data.filter(p => p.category === 'adults').reduce((accumulator, pass) => {
          return accumulator + +pass.hand_bag;
        }, 0);
      }

      return adult_hand_bag;
    },
    child_hold_bag_actual() {
      let child_hold_bag = 0;
      if (this.passengers_data && this.passengers_data.length) {
        child_hold_bag = this.passengers_data.filter(p => p.category === 'children').reduce((accumulator, pass) => {
          return accumulator + +pass.hold_bag;
        }, 0);
      }

      return child_hold_bag;
    },
    child_hand_bag_actual() {
      let child_hand_bag = 0;
      if (this.passengers_data && this.passengers_data.length) {
        child_hand_bag = this.passengers_data.filter(p => p.category === 'children').reduce((accumulator, pass) => {
          return accumulator + +pass.hand_bag;
        }, 0);
      }

      return child_hand_bag;
    },
    firstReturnRoute() {
      return this.flight.return_routes && this.flight.return_routes.length ? this.flight.return_routes[0] : null;
    },
  },
  methods: {
    ...mapActions('flights', {
      fetchFlightDetails: 'fetchFlightDetails',
      updateFlightDetails: 'updateFlightDetails',
      createFlightsOrder: 'createFlightsOrder',
      clearFlightDetailsState: 'clearFlightDetailsState',
    }),
    ...mapActions("alert", {
      errorAlert: "error",
      infoAlert: "info",
    }),
    initData() {
      const provider = this.$route.params.provider;
      const booking_token = this.$route.params.booking_token;
      const passengers = this.$route.params.passengers;
      const baggage = this.$route.params.baggage;

      let passengersByAge = passengers.split('-');
      if (!passengersByAge || !Array.isArray(passengersByAge) || passengersByAge.length !== 3) {
        passengersByAge = [0, 0, 0];
      }
      const adults = passengersByAge[0];
      const children = passengersByAge[1];
      const infants = passengersByAge[2];

      let baggageParts = baggage.split('-');
      if (!baggageParts || !Array.isArray(baggageParts) || baggageParts.length !== 4) {
        baggageParts = [0, 0, 0, 0];
      }
      const adult_hold_bag = +baggageParts[0];
      const adult_hand_bag = +baggageParts[1];
      const child_hold_bag = +baggageParts[2];
      const child_hand_bag = +baggageParts[3];

      this.provider = provider;
      this.booking_token = booking_token;
      this.passengers = passengers;
      this.baggage = baggage;

      this.adults = adults;
      this.children = children;
      this.infants = infants;

      this.adult_hold_bag = adult_hold_bag;
      this.adult_hand_bag = adult_hand_bag;
      this.child_hold_bag = child_hold_bag;
      this.child_hand_bag = child_hand_bag;
    },
    initPassengersData() {
      if (this.passengers_data.length || !this.flight_details_state || !this.baggage_availability) {
        return false;
      }
      const passengerDataTemplate = {
        category: 'adults', // adult/children/infants
        hand_bag: 0, // 0 or 1
        hold_bag: 0, // 0 or 1 or 2 if possible
        birthday: null, // YYYY-MM-DD format
        cardno: null,
        expiration: null, // YYYY-MM-DD
        // email: null,
        // phone: null, // "+77778887788"
        name: "",
        surname: "",
        nationality: "IL", //  has to be in ISO 3166-1 alpha-2 format
                           // (nationality of the passenger, not the country
                           // where the travel document was issued)
        gender: "male", // male/female
        main: false // true or false
      };

      let passengersData = [];
      let adult_hold_bag_used = 0;
      let adult_hand_bag_used = 0;
      let child_hold_bag_used = 0;
      let child_hand_bag_used = 0;
      let infant_hold_bag_used = 0;
      let infant_hand_bag_used = 0;
      for (let i = 0; i < this.adults; i++) {
        let passData = JSON.parse(JSON.stringify(passengerDataTemplate));
        passData.category = 'adults';
        passData.hand_bag = 0;
        if (adult_hand_bag_used < this.adult_hand_bag
          && this.baggage_availability.adults.hand_bag_available) {
          passData.hand_bag = 1;
          adult_hand_bag_used++;
        }
        passData.hold_bag = 0;
        if (adult_hold_bag_used < this.adult_hold_bag
          && (this.baggage_availability.adults.hold_bag_available || this.baggage_availability.adults.second_hold_bag_available)) {
          let hold_bag_num = 0;
          if (
              (this.adult_hold_bag - adult_hold_bag_used >= 2)
              && this.hold_second_bag_available
              && this.baggage_availability.adults.second_hold_bag_available
              && (this.adult_hold_bag - adult_hold_bag_used !== this.adults - i)
          ) {
            hold_bag_num = 2;
          } else if (this.baggage_availability.adults.hold_bag_available) {
            hold_bag_num = 1;
          }

          adult_hold_bag_used += hold_bag_num;
          passData.hold_bag = hold_bag_num;
        }

        passengersData.push(passData);
      }
      for (let i = 0; i < this.children; i++) {
        let passData = JSON.parse(JSON.stringify(passengerDataTemplate));
        passData.category = 'children';
        passData.hand_bag = 0;
        if (child_hand_bag_used < this.child_hand_bag
            && this.baggage_availability.children.hand_bag_available) {
          passData.hand_bag = 1;
          child_hand_bag_used++;
        }
        passData.hold_bag = 0;
        if (child_hold_bag_used < this.child_hold_bag
          && (this.baggage_availability.children.hold_bag_available || this.baggage_availability.children.second_hold_bag_available)) {
          let hold_bag_num = 0;

            if (
                (this.child_hold_bag - child_hold_bag_used >= 2)
                && this.hold_second_bag_available
                && this.baggage_availability.children.second_hold_bag_available
                && (this.child_hold_bag - child_hold_bag_used !== this.children - i)
            ) {
            hold_bag_num = 2;
          } else if (this.baggage_availability.children.hold_bag_available) {
            hold_bag_num = 1;
          }

          child_hold_bag_used += hold_bag_num;
          passData.hold_bag = hold_bag_num;
        }

        passengersData.push(passData);
      }

      let infant_hold_bag = +this.adult_hold_bag - +adult_hold_bag_used + +this.child_hold_bag - +child_hold_bag_used;
      let infant_hand_bag = +this.adult_hand_bag - +adult_hand_bag_used + +this.child_hand_bag - +child_hand_bag_used;
      for (let i = 0; i < this.infants; i++) {
        let passData = JSON.parse(JSON.stringify(passengerDataTemplate));
        passData.category = 'infants';
        passData.hand_bag = 0;
        if (infant_hand_bag_used < infant_hand_bag
            && this.baggage_availability.infants.hand_bag_available) {
            passData.hand_bag = 1;
            infant_hand_bag_used++;
        }
        passData.hold_bag = 0;
        if (infant_hold_bag_used < infant_hold_bag
          && (this.baggage_availability.infants.hold_bag_available || this.baggage_availability.infants.second_hold_bag_available)) {
          let hold_bag_num = 0;

          if (
            (infant_hold_bag - infant_hold_bag_used >= 2)
            && this.hold_second_bag_available
            && this.baggage_availability.infants.second_hold_bag_available
            && (infant_hold_bag - infant_hold_bag_used !== this.infants - i)
          ) {
            hold_bag_num = 2;
          } else if (this.baggage_availability.infants.hold_bag_available) {
            hold_bag_num = 1;
          }

          infant_hold_bag_used += hold_bag_num;
          passData.hold_bag = hold_bag_num;
        }

        passengersData.push(passData);
      }

      this.passengers_data = JSON.parse(JSON.stringify(passengersData));
    },
    async initCheckFlightProcess() {
      let obj = this;
      if (!obj.flight_details_state
        || obj.checkFlightsInterval) {
        return;
      }

      obj.checkFlightsInterval = setInterval(async () => {
        await flightsService.checkFlight(
          obj.provider,
          obj.booking_token,
          obj.adults,
          obj.children,
          obj.infants,
          obj.adult_hold_bag_actual,
          obj.adult_hand_bag_actual,
          obj.child_hold_bag_actual,
          obj.child_hand_bag_actual,
          obj.session_id
        ).then(
          async (result) => {
            // console.log(result);

            if (result) {
              obj.flights_check_result = result;
              if (!obj.session_id && result.session_id) {
                  obj.session_id = result.session_id;
              }

              obj.flights_checked = result.flights_checked;
              obj.price_change = result.price_change;
              obj.flights_invalid = result.flights_invalid;

              if (obj.flights_invalid) {
                obj.errorAlert(obj.$t("FLIGHTS.FLIGHT_IS_NOT_AVAILABLE"));
                obj.clearCheckFlightsInterval();
                await obj.redirectBack();
              }
              // if (obj.price_change) {
                let changed_price = result.changed_price;
                let changed_bagsprice = result.changed_bagsprice;

                let current_price = obj.flight.price;
                let current_bagsprice = obj.flight.bagsprice;
                if (current_price.orig_price !== changed_price.orig_price
                  || current_bagsprice.first_baggage !== changed_bagsprice.first_baggage
                  || current_bagsprice.second_baggage !== changed_bagsprice.second_baggage
                  || current_bagsprice.hand !== changed_bagsprice.hand) {

                    obj.infoAlert(obj.$t("FLIGHTS.PRICE_CHANGED"));
                    obj.updateFlightDetails({
                      price: changed_price,
                      bagsprice: changed_bagsprice,
                    });

                }
              // }
            }
            return result;
          },
          (error) => {
            return error;
          }
        );

      }, 15 * 1000);
    },
    async redirectToFlightsHome() {
      await this.$router.push({ name: 'FlightHome'});
    },
    async redirectToFlightsOrder(id) {
      await this.$router.push({ name: 'FlightsOrder', params: {id: id}});
    },
    async redirectBack() {
      await this.$router.back();
    },
    clearCheckFlightsInterval() {
      if (this.checkFlightsInterval) {
        clearInterval(this.checkFlightsInterval);
        this.checkFlightsInterval = null;
      }
    },
    openOrderModal() {
      this.phone = '';
      this.email = '';
      this.$modal.show(this.order_modal_name);
    },
    closeOrderModal() {
      this.$modal.hide(this.order_modal_name);
      this.phone = '';
      this.email = '';
    },
    async createOrder() {
      if (!this.provider
        || !this.booking_token
        || !this.passengers_data
        || !this.phone
        || !this.email
        || !this.session_id
      ) {
        this.errorAlert(this.$t("FLIGHTS.PLEASE_CHECK_EMAIL_AND_PHONE"));
        return false;
      }

      const orderData = {
        provider: this.provider,
        booking_token: this.booking_token,
        passengers_data: this.passengers_data,
        phone: this.phone,
        email: this.email,
        session_id: this.session_id,
      };

      this.createFlightsOrder(orderData).then(
        async result => {
            // console.log(result)
          if (result && result.order && result.order.id) {
            await this.redirectToFlightsOrder(result.order.id);
          } else {
            this.errorAlert(this.$t("FLIGHTS.ERROR"));
          }
          return result;
        },
        async error => {
          this.errorAlert(error.response.data.message || this.$t("FLIGHTS.ERROR"));
          return error;
        }
      );
      this.closeOrderModal();
    }
  },
  async created() {
    this.clearFlightDetailsState();
    this.initData();
    this.initPassengersData();

    await this.fetchFlightDetails({
      provider: this.provider,
      booking_token: this.booking_token,

      adults: this.adults,
      children: this.children,
      infants: this.infants,

      adult_hold_bag: this.adult_hold_bag,
      adult_hand_bag: this.adult_hand_bag,
      child_hold_bag: this.child_hold_bag,
      child_hand_bag: this.child_hand_bag,
    }).then(
      result => {
        if (result && result.session_id) {
          this.session_id = result.session_id;
        }
        return result;
      },
      async error => {
        this.errorAlert(this.$t("FLIGHTS.FLIGHT_IS_NOT_AVAILABLE"));
        await this.redirectBack();
        return error;
      }
    );

    this.initCheckFlightProcess();
  },
  beforeUnmount() {
    this.clearCheckFlightsInterval();
  },
  unmounted() {
    this.clearCheckFlightsInterval();
  },
  destroyed() {
    this.clearCheckFlightsInterval();
  },
  watch: {
      flight_details_state: {
          handler (newValue, oldValue) {
              if (newValue) {
                  this.initCheckFlightProcess();
                  this.initPassengersData();
              }
          },
          deep: true,
      },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
.order-btn {
  margin: 0;
  margin-top: 15px;
}

</style>